import React, { lazy } from "react";
import { useMediaQuery } from "@material-ui/core";
import { Switch, Route } from "react-router-dom"; 

const MainHome = lazy(() => import("./pages/MainHome/MainHome")); 
const NotFound = lazy(() => import("./pages/NotFound/NotFound")); 
const Social = lazy(() => import("./components/Social/Social"));
const Loader = lazy(() => import("./components/Loader/Loader"));
const NavbarRight = lazy(() => import("./components/Navbar/NavbarRight"));
const NavbarLeft = lazy(() => import("./components/Navbar/NavbarLeft"));
const Header = lazy(() => import("./components/Navbar/Header"));
const SecondDisplacementSphere = lazy(() => import("../src/sections/DisplacementSphere/SecondDisplacementSphere")); 

const Routes = () => { 
    const isMobile = useMediaQuery("(max-width:760px)");
    return (
        <>
            <Loader />
            {!isMobile && <Header />}
            {!isMobile && <SecondDisplacementSphere />}
            {!isMobile && <NavbarLeft />}
            {!isMobile && <Social />}
            {isMobile && <NavbarRight />}
            <Switch>
                <Route exact path="/" component={MainHome} />
                <Route path={"*"} exact={true} component={NotFound} />
            </Switch> 
        </>
    );
};

export default Routes; 