import { useState, Suspense } from "react";
import { ThemeProvider } from "@material-ui/styles";
import { defaultTheme } from "./assets/theme/theme";
import CssBaseline from "@material-ui/core/CssBaseline";
import "./App.css";
import "./styles/MainStyle.scss"; 
import "./styles/Responsive.scss"; 
import ScrollToTop from "./components/ScrollToTop";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes"; 
import LoaderContext from "./contexts/loaderContext";

function App() { 
    const [isLoading, setIsLoading] = useState(true); 

    const documentHeight = () => {
        const doc = document.documentElement
        doc.style.setProperty('--main-height', `${window.innerHeight}px`)
    }

    window.addEventListener("resize", documentHeight)
    documentHeight(); 

    return (
        <Router basename="/sforpay.com">
            <Suspense fallback={<div></div>}> 
                    <LoaderContext.Provider value={{ isLoading, setIsLoading }}>
                        <ThemeProvider theme={defaultTheme}>
                            <CssBaseline />
                            <ScrollToTop />
                            <Routes />
                        </ThemeProvider>
                    </LoaderContext.Provider> 
            </Suspense>
        </Router>
    );
}

export default App;
